<template>
  <div>
    <div id="view">
      <h3>
        Auf Basis eurer Simulationserfahrungen: <br />
        Welches <b>Persönlichkeitsprofil*</b> beschreibt das „Wir“ im
        metaZukunftsraum "interLab"?
      </h3>
      <p>
        Bitte bewege die Slider, deinem Persönlichkeitsprofil entsprechend, nach
        links oder rechts. Du musst dich entscheiden.
      </p>
      <div id="sliders">
        <div
          class="slider-setup flex-row"
          v-for="(slider, id) in sliderContent"
          :key="id"
        >
          <div class="slider-width">
            <div id="slider-container">
              <b-progress
                class="absolut-back"
                :value="slider.param"
                max="1"
                variant="dark"
                height="10px"
              ></b-progress>
              <div id="middle-mark"></div>
              <b-form-input
                v-model="slider.param"
                type="range"
                min="0"
                max="1"
                step="0.01"
                class="absolut-front"
              ></b-form-input>
            </div>
            <div id="labels">
              <p class="no-margin">{{ slider.labels[0] }}</p>
              <p class="no-margin">{{ slider.labels[1] }}</p>
            </div>
          </div>
          <div id="button-container" class="icon">
            <b-button
              variant="secondary"
              v-b-modal.modal-1
              :id="id"
              class="rounded-circle px-2"
              @click="setModalContent(id)"
              size="sm"
            >
              <b-icon
                icon="question-circle"
                variant="dark"
                scale="1.5"
              ></b-icon>
            </b-button>
          </div>
        </div>
        <p>
          *Die Parameter basieren auf dem renommierten "Big Five“-Modell der
          Persönlichkeitspsychologie.
        </p>
      </div>
    </div>

    <!-- Modal -->
    <b-modal id="modal-1" centered scrollable :hide-footer="false">
      <template #modal-title class="p-margin-header">
        <p>
          <b>{{ currentModalContent.title }}</b>
        </p>
      </template>
      <p class="p-margin">
        <b>{{ currentModalContent.headline1 }}: </b>
        {{ currentModalContent.copytext1 }}
      </p>
      <p>
        <b>{{ currentModalContent.headline2 }}: </b>
        {{ currentModalContent.copytext2 }}
      </p>
      <template #modal-footer="{ ok }">
        <b-button variant="primary" size="sm" squared @click="ok()">
          {{ currentModalContent.buttonText }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Creation_2_Characteristics",
  data: function () {
    return {
      // sliderValue: [0.5, 0.5, 0.5, 0.5, 0.5],
      currentModalContent: {
        title: "",
        headline1: "",
        copytext1: "",
        headline2: "",
        copytext2: "",
        buttonText: "Alles klar!",
      },
    };
  },
  computed: {
    sliderContent() {
      // console.log(this.$store.state.content.characteristics.sliders[0].param);

      for (
        let i = 0;
        i < this.$store.state.content.characteristics.sliders.length;
        i++
      ) {
        let param = {
          id: i,
          value: this.$store.state.content.characteristics.sliders[i].param,
        };
        this.$store.dispatch("setAvatarParameter", param);
      }

      return this.$store.state.content.characteristics.sliders;
    },
    modalHeadline() {
      return this.$store.state.content.characteristics.slidersModalHeadline;
    },
  },
  mounted() {},
  methods: {
    // sendSliderValue(id) {
    //   // TODO set value different from 0.5
    //   console.log(this.sliderValue[id]);
    // },
    setModalContent(id) {
      this.currentModalContent.title = this.modalHeadline;
      this.currentModalContent.headline1 = this.sliderContent[id].labels[0];
      this.currentModalContent.headline2 = this.sliderContent[id].labels[1];
      this.currentModalContent.copytext1 = this.sliderContent[id].explanation1;
      this.currentModalContent.copytext2 = this.sliderContent[id].explanation2;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/app.scss";

#view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 10vh - 50px - 40px - 16px);
  overflow: auto;
  pointer-events: auto;
}
#sliders {
  overflow: auto;
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0) 100%
  );
   margin-right: 0;
}
.slider-setup {
  pointer-events: all;
  margin: 30px;
  margin-top: 5px;
  margin-right: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.icon {
  margin: 25px;
  margin-top: 5px;
  margin-right: 16px;
}
.no-margin {
  margin: 0;
}
.slider-width {
  width: 100%;
}
#button-container .btn-secondary {
  color: $black;
  background-color: #ffffff00;
  border-color: #ffffff00;
}
.absolut-back,
.absolut-front {
  position: absolute;
  top: 0;
  width: 100%;
}
#middle-mark {
  background-color: rgb(255, 255, 255);
  position: absolute;
  margin-left: calc(50% - 1px);
  top: 6.5px;
  width: 2px;
  height: 10px;
  z-index: 50;
}
.absolut-front {
  z-index: 100;
}
.absolut-back {
  top: 6.5px;
}
#slider-container {
  position: relative;
  height: 20px;
  
  .bg-dark {
  background-color: #343434 !important;
}
}
#slider-container .custom-range {
  &::-webkit-slider-thumb {
    border: #343434 solid 2px;
  }
  &::-moz-range-thumb {
    border: #343434 solid 2px;
  }
  &::-ms-thumb {
    border: #343434 solid 2px;
  }
}
h3 {
  margin-left: 30px;
  font-size: 1.4em;
}
p {
  margin-left: 30px;
}
#modal-1 p {
  margin: 10px;
  margin-right: 15px;
  margin-left: 15px;
}
@media (min-device-width: 1500px) {
  h3 {
    font-size: 1.6em;
  }
  p {
    font-size: 1.1em;
  }
}

@media (min-device-width: 2000px) {
  #view {
    height: calc(100vh - 10vh - 50px - 40px - 55px - 25px);
  }
}
</style>