<template>
  <div>
    <div id="container-three-scene-underlay">
      <img
        src="../assets/Aufheller_radialRamp.png"
        class="img-back"
        id="Hotspot100"
      />
      <!-- <div id="hexa-image"></div> -->
      <!-- <img src="../assets/FUTURE_Hexa_Mask.svg" id="hexa-mask" /> -->

      <div id="Hotspot102">
        <div v-if="currentDay == 4">
          <img
            src="../assets/FUTURE_Hexa_angeschnitten.svg"
            class="img-future future-hex"
          />

          <img
            src="../assets/FUTURE_Iso_Quad_unten.svg"
            class="img-future future-quad"
          />
        </div>
      </div>
      <div
        v-for="bLottie in backLotties"
        :key="bLottie.id"
        :id="bLottie.id"
        class="lottie-offset"
      ></div>
      <!-- Hexagon etc. -->
      <div
        v-for="lLottie in loopLotties"
        :key="lLottie.id"
        :id="lLottie.id"
        class="loop-lottie-offset"
      ></div>
    </div>
    <div id="container-three-scene">
      <canvas id="canvas-three-scene"></canvas>
    </div>
    <div id="container-three-scene-overlay">
      <!-- Lotties -->
      <div
        v-for="fLottie in frontLotties"
        :key="fLottie.id"
        :id="fLottie.id"
        class="lottie-offset"
      ></div>
      <div id="Hotspot103" class="hind-font">
        <transition name="fadeOpacity" mode="out-in">
          <!-- Team members / last step -->
          <div v-if="currentDay < 4 && activeStep == 6">
            <p v-for="member in teammembers" :key="member">{{ member }}</p>
          </div>
          <!-- Avatar slogan / reveal -->
          <div v-if="currentDay == 4">
            <img
              v-if="currentDay == 4 || activeStep == 6"
              src="../assets/FUTURE_Typo_Artefakt_2022_2.svg"
              id="slogan"
            />
          </div>
        </transition>
      </div>
      <transition name="fadeOpacity" mode="out-in">
        <img
          v-if="currentDay == 4 || activeStep == 6"
          src="../assets/Metafinanz_Logo_RGB.svg"
          id="logo"
        />
      </transition>
      <!-- UI -->
      <UiOverlay />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import lottie from "lottie-web";
import UiOverlay from "./UiOverlay.vue";

export default {
  name: "ThreeScene",
  data() {
    return {
      script: "",
      currentDay: 0,
      teammembers: "",
      activeStep: 0,
      loopLotties: [
        {
          path: "/lotties/Hexa_Iso_Struktur_bw_ani_04_versetzt.json",
          id: "Hotspot101",
          width: 100,
          height: 100,
        },
      ],
      frontLotties: [
        {
          path: "/lotties/1_1_INTROV_EXTROV.json",
          id: "Hotspot01",
          paramId: 0,
          paramType: "characteristics",
          width: 30,
          height: 30,
        },
        {
          path: "/lotties/1_2_OFFEN_BESTAENDIG.json",
          id: "Hotspot02",
          paramId: 1,
          paramType: "characteristics",
          width: 30,
          height: 30,
        },
        {
          path: "/lotties/1_3_GEWISSENHAFT_FLEX_4_fade.json",
          id: "Hotspot03",
          paramId: 2,
          paramType: "characteristics",
          width: 30,
          height: 30,
        },
        {
          path: "/lotties/1_4_KOOPERATIV_KOMPETITIV.json",
          id: "Hotspot04",
          paramId: 3,
          paramType: "characteristics",
          width: 28,
          height: 28,
        },
        {
          path: "/lotties/2_1_1_Zugehoerigkeitsgefuehl_3_5px.json",
          id: "Hotspot06",
          paramId: 5,
          paramType: "motives",
          width: 32,
          height: 32,
        },
        {
          path: "/lotties/2_1_2_Loyalitaet_zum_Team.json",
          id: "Hotspot07",
          paramId: 6,
          paramType: "motives",
          width: 20,
          height: 20,
        },
        {
          path: "/lotties/2_1_3_gem_Aufgaben_Ziele_erreichen.json",
          id: "Hotspot08",
          paramId: 7,
          paramType: "motives",
          width: 20,
          height: 20,
        },
        {
          path: "/lotties/2_2_1_Uebereinst_Werte.json",
          id: "Hotspot09",
          paramId: 8,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_2_2_Idealismus.json",
          id: "Hotspot10",
          paramId: 9,
          paramType: "motives",
          width: 20,
          height: 20,
        },
        {
          path: "/lotties/2_2_3_Wunsch_nach_Resonanz.json",
          id: "Hotspot11",
          paramId: 10,
          paramType: "motives",
          width: 20,
          height: 20,
        },
        {
          path: "/lotties/2_3_1_Streben_Expertise.json",
          id: "Hotspot12",
          paramId: 11,
          paramType: "motives",
          width: 15,
          height: 15,
        },
        {
          path: "/lotties/2_3_2_Aufgaben_Zufriedenheit_erf.json",
          id: "Hotspot13",
          paramId: 12,
          paramType: "motives",
          width: 13,
          height: 13,
        },
        {
          path: "/lotties/2_3_3_Messbare_Erfolge.json",
          id: "Hotspot14",
          paramId: 13,
          paramType: "motives",
          width: 17,
          height: 17,
        },
        {
          path: "/lotties/2_4_1_Freude_an_Herausf.json",
          id: "Hotspot15",
          paramId: 14,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_4_2_Grenzen_verschieben.json",
          id: "Hotspot16",
          paramId: 15,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_4_3_Besonderes_erreichen.json",
          id: "Hotspot17",
          paramId: 16,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_5_1_neues_Wissen.json",
          id: "Hotspot18",
          paramId: 17,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_5_2_Loesungen_kompl_Aufg_iso.json",
          id: "Hotspot19",
          paramId: 18,
          paramType: "motives",
          width: 30,
          height: 30,
        },

        {
          path: "/lotties/2_5_3_frei_Denken.json",
          id: "Hotspot20",
          paramId: 19,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_6_1_Anerkennung.json",
          id: "Hotspot21",
          paramId: 20,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_6_2_Macht_haben.json",
          id: "Hotspot22",
          paramId: 21,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_6_3_Verantwortung.json",
          id: "Hotspot23",
          paramId: 22,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_7_1_Autonomie.json",
          id: "Hotspot24",
          paramId: 23,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_7_2_eigenbest_Entscheidungsspielr.json",
          id: "Hotspot25",
          paramId: 24,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_7_3_Selbststaendigkeit.json",
          id: "Hotspot26",
          paramId: 25,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_8_1_stabile_Beziehungen.json",
          id: "Hotspot27",
          paramId: 26,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_8_2_gesicherte_Arbeitsplaetze.json",
          id: "Hotspot28",
          paramId: 27,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/2_8_3_ausr-Orientierung_Kontrolle.json",
          id: "Hotspot29",
          paramId: 28,
          paramType: "motives",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_1_Netzwerken.json",
          id: "Hotspot30",
          paramId: 29,
          paramType: "skills",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_2_Entscheiden.json",
          id: "Hotspot31",
          paramId: 30,
          paramType: "skills",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_3_Empathie.json",
          id: "Hotspot32",
          paramId: 31,
          paramType: "skills",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_4_Kreativitaet.json",
          id: "Hotspot33",
          paramId: 32,
          paramType: "skills",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_5_Ueberzeugungsvermoegen.json",
          id: "Hotspot34",
          paramId: 33,
          paramType: "skills",
          width: 20,
          height: 20,
        },

        {
          path: "/lotties/3_6_Konfliktkompentenz.json",
          id: "Hotspot35",
          paramId: 34,
          paramType: "skills",
          width: 25,
          height: 25,
        },

        {
          path: "/lotties/3_7_Zukunftswahrnehmung.json",
          id: "Hotspot36",
          paramId: 35,
          paramType: "skills",
          width: 20,
          height: 20,
        },
      ],
      backLotties: [
        {
          path: "/lotties/1_5_SENSIBEL_STABIL.json",
          id: "Hotspot05",
          paramId: 4,
          paramType: "characteristics",
          width: 33,
          height: 33,
        },
      ],
      allLottieInstances: [],
    };
  },
  computed: mapState(["avatarParameters"]),
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "m_setAvatarParameter" ||
        mutation.type === "m_setCurrentAvatarData"
      ) {
        this.allLottieInstances.forEach((tempLottie) => {
          if (tempLottie.paramType === "characteristics") {
            tempLottie.goToAndStop(
              state.currentAvatarData.parameters[tempLottie.paramId] *
                tempLottie.totalFrames,
              true
            );
          } else if (
            tempLottie.paramType === "motives" ||
            tempLottie.paramType === "skills"
          ) {
            let direction =
              state.currentAvatarData.parameters[tempLottie.paramId] < 0.5
                ? -1 ///////////////////////////////////////////////////////////
                : 1;
            if (
              (tempLottie.lastDirection != undefined &&
                tempLottie.lastDirection != direction) ||
              (tempLottie.lastDirection === undefined && direction === 1)
            ) {
              tempLottie.stop();
              tempLottie.setDirection(direction);
              let startFrame = direction === -1 ? tempLottie.totalFrames : 0;
              tempLottie.goToAndPlay(startFrame, true);
              tempLottie.lastDirection = direction;
            }
          }
        });

        let _teammembers = state.currentAvatarData.teammembers.split(",");
        for (let i = 0; i < _teammembers.length; i++) {
          _teammembers[i] = _teammembers[i].trim();
        }
        this.teammembers = _teammembers;
        this.currentDay = state.currentDay;
      } else if (mutation.type === "m_setActiveStep") {
        this.activeStep = state.activeStep;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  components: {
    UiOverlay,
  },
  mounted() {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      ) ||
      navigator.userAgent.toLowerCase().match(/mobile/i)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.$store.dispatch("requestInitialSetup");

    this.script = require("@/scripts/three-scene.js");
    this.script.main(
      this.isMobile,
      this.$store.state.activeStep,
      this.$store.state.currentDay
    );
    this.backLotties.forEach((bLottie) => {
      let anim = lottie.loadAnimation({
        container: document.getElementById(bLottie.id),
        name: bLottie.id,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: bLottie.path,
      });
      anim.paramType = bLottie.paramType;
      anim.paramId = bLottie.paramId;

      style(document.getElementById(bLottie.id), {
        width: bLottie.width ? `${bLottie.width}%` : "10%",
        height: bLottie.height ? `${bLottie.height}%` : "10%",
        overflow: "hidden",
        margin: "0 auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        "pointer-events": "none",
      });
      this.allLottieInstances.push(anim);
    });

    this.frontLotties.forEach((fLottie) => {
      let anim = lottie.loadAnimation({
        container: document.getElementById(fLottie.id),
        name: fLottie.id,
        renderer: "svg",
        loop: false,
        autoplay: false,
        path: fLottie.path,
      });
      anim.paramType = fLottie.paramType;
      anim.paramId = fLottie.paramId;

      style(document.getElementById(fLottie.id), {
        width: fLottie.width ? `${fLottie.width}%` : "10%",
        height: fLottie.height ? `${fLottie.height}%` : "10%",
        overflow: "hidden",
        margin: "0 auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        "pointer-events": "none",
      });
      this.allLottieInstances.push(anim);
    });

    this.loopLotties.forEach((lLottie) => {
      let anim = lottie.loadAnimation({
        container: document.getElementById(lLottie.id),
        name: lLottie.id,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: lLottie.path,
      });

      style(document.getElementById(lLottie.id), {
        width: lLottie.width ? `${lLottie.width}%` : "10%",
        height: lLottie.height ? `${lLottie.height}%` : "10%",
        overflow: "hidden",
        margin: "0 auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        "pointer-events": "none",
      });
    });

    function style(element, style) {
      for (const property in style) {
        element.style[property] = style[property];
      }
    }

    window.addEventListener(
      "hotspotPositionChanged",
      (e) => {
        document.getElementById("Hotspot" + e.detail.id).style.top =
          e.detail.y + "px";
        document.getElementById("Hotspot" + e.detail.id).style.left =
          e.detail.x + "px";
      },
      false
    );
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#container-three-scene-underlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
}
#container-three-scene {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
}
#container-three-scene-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
}
#canvas-three-scene {
  top: 0px;
  left: 0px;
  position: absolute;
  pointer-events: all;
}
#logo {
  height: 10%;
  width: 10%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
#slogan {
  width: 50%;
  height: 50%;
  margin: 6px;
}
.img-back {
  height: 120%;
  width: 120%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.img-future {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 108%;
  height: 108%;
  transform: translate(-50%, -50%);
}
.future-quad {
  opacity: 0.5;
}
.future-hex {
  opacity: 0.3;
}
.fadeOpacity-enter-active,
.fadeOpacity-leave-active {
  transition: all 0.7s;
  opacity: 1;
  transition-timing-function: ease-in-out;
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
}
.fadeOpacity-enter,
.fadeOpacity-leave-to {
  opacity: 0;
  transition-timing-function: ease-in-out;
}
.hind-font {
  font-family: hind, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #7b7b7b;
}
#Hotspot102 {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#Hotspot103 {
  padding-left: 12px;
  position: absolute;
  top: 0%;
  left: 0%;
  pointer-events: none;
  transform: translate(0, -50%);
}
.loop-lottie-offset {
  transform: translate(-50%, -50%);
  position: relative;
}
.lottie-offset {
  transform: translate(-50%, -50%);
  position: absolute;
}
p {
  margin: 5px;
}
</style>
