<template>
  <div id="container">
    <div id="view">
      <div id="headline">
        <h3>
          Auf Basis eurer Simulationserfahrungen: <br />
          An welchen <b>3 Motiven</b> sollten sich Handlungen und Entscheidungen im
          metaZukunftsraum "interLab" überwiegend ausrichten?
        </h3>
      </div>
      <div id="checkboxes" >
        <div v-for="(checkboxGroup, id) in checkboxesContent" :key="id" class="check-margin">
          <h5>{{ checkboxGroup.label }}</h5>
          <div v-for="(label, id2) in checkboxGroup.boxlabels" :key="label">
            <b-form-checkbox
              v-if="checkboxesStates[id * 3 + id2] === false && state === false"
              v-model="checkboxesStates[id * 3 + id2]"
              stacked
              class="allow-pointer box-margin"
              variant="dark"
              :state="state"
              disabled
              >{{ label }}</b-form-checkbox
            >
            <b-form-checkbox
              v-else
              v-model="checkboxesStates[id * 3 + id2]"
              stacked
              class="allow-pointer"
              variant="dark"
              :state="state"
              >{{ label }}</b-form-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creation_3_Motives",
  data() {
    return {
      selection: [],
    };
  },
  computed: {
    state() {
      return (
        this.checkSelectionCount(
          this.$store.state.content.motives.checkboxesStates
        ) < 3
      );
    },
    checkboxesContent() {
      return this.$store.state.content.motives.checkboxGroups;
    },
    modalHeadline() {
      return this.$store.state.content.motives.checkboxesModalHeadline;
    },
    modalCopytext() {
      return this.$store.state.content.motives.checkboxesModalCopytext;
    },
    checkboxesStates() {
      // console.log(this.$store.state.content.motives.checkboxesStates);

      for (
        let i = 0;
        i < this.$store.state.content.motives.checkboxesStates.length;
        i++
      ) {
        let param = {
          id: i + this.$store.state.content.characteristics.sliders.length,
          value: this.$store.state.content.motives.checkboxesStates[i] ? 1 : 0,
        };
        this.$store.dispatch("setAvatarParameter", param);
      }

      return this.$store.state.content.motives.checkboxesStates;
    },
  },
  components: {},
  mounted() {},
  methods: {
    checkSelectionCount(boolarray) {
      let count = 0;
      boolarray.forEach((element) => {
        if (element === true) {
          count++;
        }
      });

      return count;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#view {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - 10vh - 50px - 40px - 16px);
  pointer-events: auto;
}
#checkboxes {
  margin: 30px;
  margin-right: 0;
  margin-top: 5px;
  width: calc(100% - 30px);

  overflow: auto;
  flex-shrink: 5;

    -webkit-mask-image: linear-gradient( rgba(0,0,0,0) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%);
  mask-image: linear-gradient( rgba(0,0,0,0) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%);
}
.allow-pointer {
  pointer-events: auto;
  width: fit-content;
}
h3 {
  margin-left: 30px;
  font-size: 1.4em;
}
h5 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.1em;
}
.check-margin{
  margin-bottom: 20px;
}
.box-margin{
  
  margin-bottom: 1px;
}
@media (min-device-width: 1500px) {
  h3 {
    font-size: 1.6em;
  }
  h5 {
    font-size: 1.2em;
  }
  p {
    font-size: 1.1em;
  }
  #checkboxes {
    padding-top: 10px;
  }
}
@media (min-device-width: 2000px) {
  #view {
    height: calc(100vh - 10vh - 50px - 40px - 55px - 25px);
  }
}
</style>