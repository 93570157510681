<template>
  <div>
    <div id="view">
      <div>
        <h3>
          Das ist nun das Artefakt, welches eure Simulationserfahrungen für den
          metaZukunftsraum "interLab" repräsentiert und weiterträgt.
        </h3>
        <br />
        <h3 class="head-margin">Eure Eingaben auf einem Blick:</h3>
        <h3 class="head-margin">
          <b>Persönlichkeitsprofil:</b> <br />{{ characteristics }}
        </h3>
        <h3 class="head-margin"><b>3 Motive:</b> <br />{{ motives }}</h3>
        <h3 class="head-margin"><b>Fähigkeit:</b><br />{{ skills }}</h3>
      </div>
      <div id="spoiler">
        <b-button variant="link" v-b-modal.modal-2 class="button-margin"
          >Spoiler-Alarm!</b-button
        >
        <p>
          Bist du zufrieden oder möchtest du noch etwas ändern? Du kannst den
          Artefakt noch bis zum Ende des Tages anpassen.
        </p>
      </div>
      <!-- Modal -->
      <b-modal id="modal-2" centered scrollable :hide-footer="false">
        <template #modal-title>
          <p>
            <b>{{ currentModalContent.title }}</b>
          </p>
        </template>
        <p>
          {{ currentModalContent.copytext }}
        </p>
        <template #modal-footer="{ ok }">
          <b-button variant="primary" size="sm" squared @click="ok()">
            {{ currentModalContent.buttonText }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creation_5_Done",
  data() {
    return {
      currentModalContent: {
        title: "SPOILER-ALARM!",
        copytext:
          "Am 27. Januar wird euer Artefakt mit den Artefakten der anderen Simulations-Teams zu einem gemeinsamen Token zusammengeführt. Es entsteht ein gemeinsames Zukunftsbild, das ihr alle mitgestaltet habt. Danke! Ihr dürft gespannt sein!",
        buttonText: "Alles klar!",
      },
    };
  },
  computed: {
    characteristics() {
      let characteristics = [];
      for (
        let i = 0;
        i < this.$store.state.content.characteristics.sliders.length;
        i++
      ) {
        let param = this.$store.state.content.characteristics.sliders[i].param;
        let characteristic = "";
        if (param <= 0.17) {
          characteristic =
            "sehr " +
            this.$store.state.content.characteristics.sliders[i].labels[0];
        } else if (param > 0.17 && param <= 0.33) {
          characteristic =
            this.$store.state.content.characteristics.sliders[i].labels[0];
        } else if (param > 0.33 && param <= 0.5) {
          characteristic =
            "eher " +
            this.$store.state.content.characteristics.sliders[i].labels[0];
        } else if (param > 0.5 && param <= 0.67) {
          characteristic =
            "eher " +
            this.$store.state.content.characteristics.sliders[i].labels[1];
        } else if (param > 0.67 && param <= 0.83) {
          characteristic =
            this.$store.state.content.characteristics.sliders[i].labels[1];
        } else if (param > 0.83) {
          characteristic =
            "sehr " +
            this.$store.state.content.characteristics.sliders[i].labels[1];
        }

        if (characteristic != "") {
          characteristics.push(characteristic);
        }
      }
      return characteristics.join(", ");
    },
    motives() {
      let motives = [];
      for (
        let i = 0;
        i < this.$store.state.content.motives.checkboxGroups.length;
        i++
      ) {
        for (
          let j = 0;
          j <
          this.$store.state.content.motives.checkboxGroups[i].boxlabels.length;
          j++
        ) {
          if (
            this.$store.state.content.motives.checkboxesStates[
              i *
                this.$store.state.content.motives.checkboxGroups[i].boxlabels
                  .length +
                j
            ] === true
          ) {
            motives.push(
              this.$store.state.content.motives.checkboxGroups[i].boxlabels[j]
            );
          }
        }
      }
      // console.log("motives", motives)
      return motives.join(", ");
    },
    skills() {
      return this.$store.state.content.skills.radioButtonLabels[
        this.$store.state.content.skills.selectedRadioButton
      ];
    },
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/app.scss";
#view {
  height: calc(100vh - 10vh - 50px - 40px - 16px);
  overflow: auto;
  pointer-events: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h3 {
  margin-left: 30px;
  font-size: 1.4em;
}
.button-margin {
  margin: 20px;
  margin-left: 16px;
  margin-bottom: 15px;
  text-decoration: underline;
  color: $black;
  font-size: 1.4rem;
}
p {
  margin-left: 30px;
  margin-bottom: 30px;
}
#modal-2 p {
  margin: 10px;
  margin-right: 15px;
  margin-left: 15px;
}
.head-margin {
  margin-bottom: 15px;
}
@media (min-device-width: 1500px) {
  h3 {
    font-size: 1.6em;
  }
  p {
    font-size: 1.1em;
  }
}

@media (min-device-width: 2000px) {
  #view {
    height: calc(100vh - 10vh - 50px - 40px - 55px - 25px);
  }
}
</style>