import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Creation data
    currentDay: 0,
    allTeams: [],
    currentTeam: "",
    currentAvatarName: "",
    currentAvatarData: {
      name: "",
      teammembers: "",
      parameters: [
        0.5,
        0.5,
        0.5,
        0.5,
        0.5,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ]
    },

    // Navigation
    stepCount: 6,
    activeStep: 1,
    paramNames: [
      'param01',
      'param02',
      'param03',
      'param04',
      'param05',
      'param06',
      'param07',
      'param08',
      'param09',
      'param10',
      'param11',
      'param12',
      'param13',
      'param14',
      'param15',
      'param16',
      'param17',
      'param18',
      'param19',
      'param20',
      'param21',
      'param22',
      'param23',
      'param24',
      'param25',
      'param26',
      'param27',
      'param28',
      'param29',
      'param30',
      'param31',
      'param32',
      'param33',
      'param34',
      'param35',
      'param36',
    ],

    // Content
    content: {
      characteristics: {
        sliders: [
          {
            labels:[ "introvertiert", "extravertiert"],
            explanation1: "denkt gern über das Leben nach, ist unabhängig vom Wohlgefallen anderer, schätzt den intensiven Austausch mit Einzelnen und kleinen Gruppen, mag Ruhe und innere Zufriedenheit",
            explanation2: "gestaltet gern soziale Situationen, hat hohes Kontaktbedürfnis, wünscht sich positive Wahrnehmung durch seine Umgebung, mag Action und Abwechslung",
            param: 0.5
          },
          {
            labels:[ "offen", "beständig"],
            explanation1: "probiert gern Neues, Kreativität und Fantasie bestimmen das Handeln, hat Interesse an vielen und immer wieder neuen Themen, diskutiert gern Grundsatzfragen und hinterfragt Bewährtes",
            explanation2: "schätzt und pflegt Bewährtes, hinterfragt und prüft Neues kritisch, orientiert sich an Daten, Fakten und dem Machbaren, bleibt verinnerlichten Werten treu, orientiert sich an Strukturen und Autoritäten",
            param: 0.5
          },
          {
            labels:[  "gewissenhaft", "flexibel"],
            explanation1: "geht diszipliniert, geplant und strukturiert vor, hat klar definierte Ziele, strebt nach Leistung und Kompetenz, plant im Voraus, schafft gern Strukturen und Ordnung, trifft Entscheidungen auf Basis von gründlichen Analysen",
            explanation2: "schätzt kreatives Chaos, improvisiert gern, kann mit Neuem und Überraschendem umgehen, handelt spontan und aus der Intuition, entscheidet von Fall zu Fall",
            param: 0.5
          },
          {
            labels:[ "kooperativ", "kompetitiv"],
            explanation1: "sieht das Gute im Menschen, sucht den Kompromiss, ist hilfsbereit, kommuniziert offen und vertrauensvoll",
            explanation2: "knüpft und pflegt Kontakte strategisch, trägt Konflikte offen aus, betont eigene Vorzüge und Erfolge, kommuniziert strategisch und weiß andere zu überzeugen und zu lenken",
            param: 0.5
          },
          {
            labels:[ "sensibel", "stabil"],
            explanation1: "nimmt äußere Reize stark wahr, ist empfindsam auch für negative Stimmungen und Risiken, hinterfragt sich",
            explanation2: "ist durch nichts aus der Fassung zu bringen, ist von äußeren Stimmungen und Einflüssen unabhängig, wirkt stets selbstsicher",
            param: 0.5
          }
        ],
        slidersModalHeadline: "Erläuterung"
      },
      motives:{
        checkboxGroups:[
          {
            label: "Beziehungen",
            boxlabels: [
              "Zugehörigkeitsgefühl",
              "Loyalität zum Team",
              "gemeinsam Aufgaben bearbeiten und Ziele erreichen"
            ]
          },
          {
            label: "Werte und Sinn",
            boxlabels: [
              "Übereinstimmung mit eigenen Werten",
              "Idealismus",
              "Wunsch nach Resonanz"
            ]
          },
          {
            label: "Leistung",
            boxlabels: [
              "Streben nach Expertise und Fachkompetenz",
              "Aufgaben zur vollsten Zufriedenheit ausführen",
              "messbare Erfolge"
            ]
          },
          {
            label: "Wachstum",
            boxlabels: [
              "Freude an großen Herausforderungen oder fast unerreichbaren Zielen",
              "Grenzen im Wettbewerb mit anderen verschieben",
              "Besonderes erreichen"
            ]
          },
          {
            label: "Kreativität",
            boxlabels: [
              "neues Wissen aneignen",
              "an Lösungen für komplexe Herausforderungen tüfteln",
              "frei und unkonventionell denken"
            ]
          },
          {
            label: "Einfluss",
            boxlabels: [
              "Anerkennung und Prestige",
              "Macht haben",
              "Verantwortung übernehmen"
            ]
          },
          {
            label: "Unabhängigkeit",
            boxlabels: [
              "Eigeninitiative und Autonomie",
              "eigenbestimmter Entscheidungsspielraum",
              "Selbstständigkeit"
            ]
          },
          {
            label: "Sicherheit",
            boxlabels: [
              "stabile Beziehungen",
              "gesicherte Arbeitsplätze",
              "ausreichend Orientierung und Kontrolle"
            ]
          }
        ],
        checkboxesModalHeadline: "Hinweis",
        checkboxesModalCopytext: "Bitte wähle genau 3 Motive aus!",
        checkboxesStates: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]
      },
      skills:{
        radioButtonLabels:[
          "Netzwerken", "Entscheiden", "Empathie", "Kreativität", "Überzeugungsvermögen", "Konfliktkompetenz", "Zukunftswahrnehmung"
        ],
        selectedRadioButton: undefined
      }
    }
  },
  getters:{
    avatarParameters: state => state.currentAvatarData.parameters
  },
  mutations: {
    // Creation data
    m_setCurrentDay(state, currentDay) {
      state.currentDay = parseInt(currentDay)
    },
    m_setAllTeams(state, allTeams) {
      let teamsArray = []
      allTeams.forEach(team => {
        teamsArray.push(team.name)
      });
      state.allTeams = teamsArray
    },
    m_setCurrentTeam(state, currentTeam) {
      state.currentTeam = currentTeam
      // console.log(state.currentAvatarData)
    },
    m_setCurrentAvatarName(state, currentAvatarName) {
      let avatarName = state.currentDay === 1 ? currentAvatarName[0].day1_avatar_name :
        state.currentDay === 2 ? currentAvatarName[0].day2_avatar_name :
        currentAvatarName[0].day3_avatar_name
      state.currentAvatarName = avatarName
    },
    m_setCurrentAvatarData(state, currentAvatarData) {
      state.currentAvatarData.name = currentAvatarData[0].name
      state.currentAvatarData.teammembers = currentAvatarData[0].teammembers
      for (let i = 0; i < state.paramNames.length; i++) {
        state.currentAvatarData.parameters[i] = currentAvatarData[0][state.paramNames[i]]
      }

      // console.log(state.currentAvatarData)
    },
    m_setTeamMembers(state, teammembers) {
      state.currentAvatarData.teammembers = teammembers// && teammembers.length!= 0 ? teammembers : ""
      // console.log('members', state.currentAvatarData.teammembers)
    },
    m_setAvatarParameter(state, parameter) {
      state.currentAvatarData.parameters[parameter.id] = parseFloat( parameter.value )
                                                            
        // console.log(parameter.id, state.currentAvatarData.parameters[parameter.id])
    },
    m_setSliderValues(state, sliderValues){
      for (let i = 0; i < sliderValues.length; i++) {
        state.content.characteristics.sliders[i].param = sliderValues[i]        
      }
      // console.log(state.content.characteristics.sliders)
    },
    m_setMotiveCheckboxesState(state, checkboxesStates){
      state.content.motives.checkboxesStates = checkboxesStates
      //  console.log("check",state.content.motives.checkboxesStates)
    },
    m_setSelectedRadioButton(state, selectedRadioButton){
      state.content.skills.selectedRadioButton = selectedRadioButton
      // console.log("radio", state.content.skills.selectedRadioButton)
    },

    // Navigation
    m_setActiveStep(state, activeStep) {
      state.activeStep = activeStep
      //console.log("step", state.activeStep)
    }
  },
  actions: {
    // Creation data
    setCurrentDay({
      commit
    }, currentDay) {
      commit('m_setCurrentDay', currentDay)
    },
    setAllTeams({
      commit
    }, allTeams) {
      commit('m_setAllTeams', allTeams)
    },
    async setCurrentTeam({
      commit
    }, currentTeam) {
      commit('m_setCurrentTeam', currentTeam)
      // console.log(this.state.currentTeam)

      // get current Artefakt name and data
      async function axiosGetRequest(params, commitCommand) {
        await axios
          .get("/api/database.php", {
            params: params
          })
          .then(function (response) {
            commit(commitCommand, response.data)
            // console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      // current name
      await axiosGetRequest({
        requestcommand: "getcurrentavatarname",
        teamname: this.state.currentTeam
      }, 'm_setCurrentAvatarName')
      // console.log("store: ", this.state.currentAvatarName)
      // current data
      await axiosGetRequest({
        requestcommand: "getavatardata",
        avatarname: this.state.currentAvatarName
      }, 'm_setCurrentAvatarData')
      //  console.log("store: ", this.state.currentAvatarData.parameters)

      let sliderValues = new Array(5)
      let checkboxesStates = new Array(24)
      let selectedRadioButton = undefined
      // set Artefakt data to local values
      for (let i = 0; i < this.state.currentAvatarData.parameters.length; i++) {
        // slider values
        if(i >= 0 && i < 5)
        {
          sliderValues[i] = this.state.currentAvatarData.parameters[i]
        }
        // check boxes
        else if(i >= 5 && i < 29){
          checkboxesStates[i - 5] = this.state.currentAvatarData.parameters[i] == 1 ? true : false
          // console.log("???", checkboxesStates[i - 5], this.state.currentAvatarData.parameters[i])
        }
        else if(i >= 29){
          selectedRadioButton = this.state.currentAvatarData.parameters[i] === "1" ? i - 29 : selectedRadioButton
        }
      }
      commit('m_setSliderValues', sliderValues)
      commit('m_setMotiveCheckboxesState', checkboxesStates)
      commit('m_setSelectedRadioButton', selectedRadioButton)
    },
    setCurrentAvatarName({
      commit
    }, currentAvatarName) {
      commit('m_setCurrentAvatarName', currentAvatarName)
    },
    setCurrentAvatarData({
      commit
    }, currentAvatarData) {
      commit('m_setCurrentAvatarData', currentAvatarData)
    },
    setTeamMembers({
      commit
    }, teammembers) {
      commit('m_setTeamMembers', teammembers)
    },
    setAvatarParameter({
      commit
    }, parameter) {
      commit('m_setAvatarParameter', parameter)
    },
    setSliderValues({
      commit
    }, sliderValues) {
      commit('m_setSliderValues', sliderValues)
    },
    setMotiveCheckboxesState({
      commit
    }, checkboxesStates) {
      commit('m_setMotiveCheckboxesState', checkboxesStates)
    },
    setSelectedRadioButton({
      commit
    }, selectedRadioButton) {
      commit('m_setSelectedRadioButton', selectedRadioButton)
    },

    // Navigation
    setActiveStep({
      commit
    }, activeStep) {
      commit('m_setActiveStep', activeStep)
    },

    // axios requests here!!! async / await axios get
    async requestInitialSetup({
      commit
    }) {
      async function axiosGetRequest(params, commitCommand) {
        await axios
          .get("/api/database.php", {
            params: params
          })
          .then(function (response) {
            commit(commitCommand, response.data)
            // console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      // current day
      await axiosGetRequest({
        requestcommand: "getcurrentday"
      }, 'm_setCurrentDay')
      //console.log("store: ", this.state.currentDay, typeof(this.state.currentDay))
      await axiosGetRequest({
        requestcommand: "getteams"
      }, 'm_setAllTeams')
      // console.log("store: ", this.state.allTeams, typeof(this.state.allTeams))
    },

    async requestSuperAvatar({commit}){
      async function axiosGetRequest(params, commitCommand) {
        await axios
          .get("/api/database.php", {
            params: params
          })
          .then(function (response) {
            commit(commitCommand, response.data)
            //  console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      await axiosGetRequest({
        requestcommand: "getsuperavatar"
      }, 'm_setCurrentAvatarData')
    }
  },
  modules: {}
})
