<template>
  <div>
    <div id="ui-overlay" class="background-gradient">
      <!-- Header -->
      <transition name="fadeXPosition" mode="out-in" id="heading">
        <div id="headline" :key="headline">
          <h1 v-if="currentDay < 4">{{ headline }}</h1>
          <h2 v-if="currentDay < 4 && activePage == 6">
            Team: {{ activeTeam }}
          </h2>
        </div>
      </transition>
      <!-- Hide After Reveal of Super Avatar -->
      <div v-if="currentDay < 4">
        <!-- Content / Router View -->
        <transition name="fadeXPositionDelayed" mode="out-in" id="content">
          <creation-1-team v-if="activePage === 1"></creation-1-team>
          <creation-2-characteristics
            v-if="activePage === 2"
          ></creation-2-characteristics>
          <creation-3-motives v-if="activePage === 3"></creation-3-motives>
          <creation-4-skills v-if="activePage === 4"></creation-4-skills>
          <creation-5-done v-if="activePage === 5"></creation-5-done>
        </transition>
        <!-- Footer / Navigation -->
        <div id="navigation" v-if="activePage < 6">
          <!-- previous button -->
          <b-button
            v-if="activePage > 1"
            @click="ClickPrev"
            squared
            variant="secondary"
            class="boxshadow fixed-width button-left"
            id="prevButton"
            >vorheriger Schritt</b-button
          >
          <b-button
            v-else
            @click="ClickPrev"
            squared
            disabled
            variant="secondary"
            class="boxshadow fixed-width button-left"
            >vorheriger Schritt</b-button
          >
          <!-- paging -->
          <div id="paging">
            <div v-for="n in 4" :key="n" :id="'page' + n" class="icons">
              <!-- circle -->
              <img
                v-if="activePage < n"
                src="../assets/Paginierung_Empty.svg"
                id="Hotspot00"
              />
              <!-- filled -->
              <img
                v-else-if="activePage == n"
                src="../assets/Paginierung_Filled.svg"
                id="Hotspot00"
              />
              <!-- checked -->
              <img
                v-else-if="activePage > n"
                src="../assets/Paginierung_Haken.svg"
                id="Hotspot00"
              />
            </div>
          </div>
          <!-- next / ready button -->
          <div id="nextButtonContainer">
            <b-button
              v-if="nextButtonEnabled"
              @click="ClickNext"
              squared
              variant="primary"
              class="boxshadow fixed-width button-right"
              >{{ nextButtonLabels[activePage - 1] }}</b-button
            >
            <b-button
              v-else
              @click="ClickNext"
              squared
              disabled
              variant="primary"
              class="boxshadow fixed-width button-right"
              >{{ nextButtonLabels[activePage - 1] }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Creation1Team from "../views/Creation_1_Team.vue";
import Creation2Characteristics from "../views/Creation_2_Characteristics.vue";
import Creation3Motives from "../views/Creation_3_Motives.vue";
import Creation4Skills from "../views/Creation_4_Skills.vue";
import Creation5Done from "../views/Creation_5_Done.vue";

export default {
  name: "UiOverlay",
  data() {
    return {
      stepRoutes: ["/team", "/characteristics", "/motives", "/skills", "/done"],
      nextButtonLabels: [
        "Nächster Schritt",
        "Nächster Schritt",
        "Nächster Schritt",
        "Fertig stellen",
        "Artefakt anzeigen",
      ],
    };
  },
  computed: {
    currentDay() {
      const event = new CustomEvent("changeCurrentDay", {
        detail: {
          currentDay: this.$store.state.currentDay,
        },
      });
      window.dispatchEvent(event);
      if (this.$store.state.currentDay === 4) {
        this.$store.dispatch("requestSuperAvatar");
      }

      let uiOverlay = document.getElementById("ui-overlay");
      if (uiOverlay) {
        if (this.$store.state.currentDay === 4 || this.activePage === 6) {
          uiOverlay.classList.add("background-transparent");
          uiOverlay.classList.remove("background-gradient");
        } else {
          uiOverlay.classList.remove("background-transparent");
          uiOverlay.classList.add("background-gradient");
        }
      }
      return this.$store.state.currentDay;
    },
    activeTeam() {
      return this.$store.state.currentTeam;
    },
    nextButtonEnabled() {
      if (
        (this.$store.state.activeStep === 1 &&
          this.$store.state.currentTeam != "") ||
        (this.$store.state.activeStep === 2 &&
          this.checkAllSlidersAreNotCentered()) ||
        (this.$store.state.activeStep === 3 &&
          this.checkSelectionCount(
            this.$store.state.content.motives.checkboxesStates
          ) === 3) ||
        (this.$store.state.activeStep === 4 &&
          this.$store.state.content.skills.selectedRadioButton != undefined) ||
        this.$store.state.activeStep === 5
      ) {
        return true;
      } else {
        return false;
      }
    },
    activePage() {
      return this.$store.state.activeStep;
    },
    headline() {
      return this.$store.state.currentDay == 4
        ? "Artefakt"
        : this.$store.state.activeStep == 5
        ? "Artefakt Creator — Geschafft!"
        : this.$store.state.activeStep == 6
        ? "Artefakt" // — " + this.activeTeam
        : "Artefakt Creator";
    },
  },
  components: {
    Creation1Team,
    Creation2Characteristics,
    Creation3Motives,
    Creation4Skills,
    Creation5Done,
  },
  mounted() {
    // document
    //   .getElementById("ui-overlay")
    //   .addEventListener("mouseenter", function () {
    //     console.log("mouse is on");
    //   });
    // document
    //   .getElementById("ui-overlay")
    //   .addEventListener("mouseout", function () {
    //     console.log("mouse is off");
    //   });
  },
  methods: {
    SetActivePage(direction) {
      let tempActivePage;
      tempActivePage = this.$store.state.activeStep + direction;
      tempActivePage =
        tempActivePage > this.$store.state.stepCount
          ? this.$store.state.stepCount
          : tempActivePage;
      tempActivePage = tempActivePage < 1 ? 1 : tempActivePage;

      this.$store.dispatch("setActiveStep", tempActivePage);

      const event = new CustomEvent("changeActiveStep", {
        detail: {
          activeStep: tempActivePage,
        },
      });
      window.dispatchEvent(event);
      let uiOverlay = document.getElementById("ui-overlay");
      if (tempActivePage === 6) {
        uiOverlay.classList.add("background-transparent");
        uiOverlay.classList.remove("background-gradient");
      } else {
        uiOverlay.classList.remove("background-transparent");
        uiOverlay.classList.add("background-gradient");
      }
    },
    async postAllAvatarData() {
      async function axiosPostRequest(formData) {
        await axios({
          method: "post",
          url: "api/database.php",
          data: formData,
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            //handle success
            //console.log(response);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      }

      // team members
      let formData = new FormData();
      formData.append("requestcommand", "postavatarparameter");
      formData.append("avatarname", this.$store.state.currentAvatarData.name);
      formData.append("parametername", "teammembers");
      formData.append(
        "parametervalue",
        this.$store.state.currentAvatarData.teammembers
      );

      await axiosPostRequest(formData);

      for (let i = 0; i < this.$store.state.paramNames.length; i++) {
        let formData = new FormData();
        formData.append("requestcommand", "postavatarparameter");
        formData.append("avatarname", this.$store.state.currentAvatarData.name);
        formData.append("parametername", this.$store.state.paramNames[i]);
        formData.append(
          "parametervalue",
          this.$store.state.currentAvatarData.parameters[i]
        );
        await axiosPostRequest(formData);
      }
    },
    ClickPrev() {
      this.SetActivePage(-1);
    },
    ClickNext() {
      this.SetActivePage(1);
      this.postAllAvatarData();
    },
    checkSelectionCount(boolarray) {
      let count = 0;
      boolarray.forEach((element) => {
        if (element === true) {
          count++;
        }
      });

      return count;
    },
    checkAllSlidersAreNotCentered() {
      let sliderValues = new Array(
        this.$store.state.content.characteristics.sliders.length
      );
      for (let i = 0; i < sliderValues.length; i++) {
        sliderValues[i] =
          this.$store.state.content.characteristics.sliders[i].param;
      }
      return this.checkAllArrayElementsDontEqualValue(sliderValues, 0.5);
    },
    checkAllArrayElementsDontEqualValue(floatarray, floatvalue) {
      let allDontEqual = true;
      floatarray.forEach((element) => {
        if (parseFloat(element) === floatvalue) {
          allDontEqual = false;
        }
      });
      return allDontEqual;
    },
    // checkMouseIsOnUi(isOnDiv) {
    //   console.log("mouse is on", isOnDiv);
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/app.scss";

// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

.fadeXPosition-enter-active,
.fadeXPosition-leave-active {
  transition: all 0.5s;
  transform: translateX(0);
  opacity: 1;
  transition-timing-function: ease-in-out;
}
.fadeXPosition-enter,
.fadeXPosition-leave-to {
  transform: translateX(-70%);
  opacity: 0;
  transition-timing-function: ease-in-out;
}
.fadeXPositionDelayed-enter-active,
.fadeXPositionDelayed-leave-active {
  transition: all 0.5s;
  transform: translateX(0);
  opacity: 1;
  transition-timing-function: ease-in-out;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
}
.fadeXPositionDelayed-enter,
.fadeXPositionDelayed-leave-to {
  transform: translateX(-70%);
  opacity: 0;
  transition-timing-function: ease-in-out;
}
#ui-overlay {
  padding-top: 15px;
  padding-left: 15px;
  position: absolute;
  top: 0;
  width: 38vw;
  min-width: 555px;
  max-width: 750px;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
}
.background-gradient {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0.4) 88%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0.4) 88%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0.4) 88%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
.background-transparent {
  background: transparent;
}
#headline {
  height: 70px;
  color: #343434;
}
#navigation {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
button {
  margin: 34px;
  margin-top: 0;
  pointer-events: all;
}
#nextButtonContainer .btn {
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: 0.4;
  }
}
.button-left {
  margin-right: 8px;
  margin-left: 30px;
}
.button-right {
  margin-left: 8px;
  margin-right: 16px;
}
#paging {
  display: flex;
  flex-direction: row;
  margin: 34px;
  margin-top: 0;
}
.icons {
  margin: 2px;
}
.boxshadow {
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}
.fixed-width {
  width: 150px;
}

h1 {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 0;
  font-weight: bold;
  letter-spacing: -0.02em;
  font-size: 2.25em;
  color: #343434;
}
h2 {
  margin-left: 30px;
  font-weight: bold;
  font-size: 1.4em;
}
@media (min-device-width: 1500px) {
  #ui-overlay {
    max-width: 850px;
  }
  h1 {
    font-size: 2.4em;
  }
  h2 {
    font-size: 1.6em;
  }
  p {
    font-size: 1.1em;
  }
}
@media (min-device-width: 2000px) {
  #ui-overlay {
    padding-top: 55px;
    padding-left: 55px;
  }
  #navigation{
    margin-bottom: 25px;
  width: calc(100% - 39px);
}
}
</style>
