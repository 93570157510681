<template>
  <div>
    <div id="view">
      <!-- Intro text -->
      <h3>
        Eure <b>Simulationserfahrungen</b> sind wichtig, um den metaZukunftsraum
        "interLab" zu gestalten! Deshalb verdichten wir eure Erfahrungen nun
        anhand von drei Fragen. Aus euren Antworten entsteht ein Artefakt, das
        die Simulationserfahrungen eurer Gruppe repräsentiert und weiterträgt.
      </h3>
      <div id="content">
        <br />
        <h2>Wähle dein Team:</h2>
        <div id="dropdown-container">
          <b-dropdown
            toggle-class="text-decoration-none"
            block
            squared
            variant="secondary"
            class="m-2 text-left"
            menu-class="w-100"
            id="dropdown"
            no-caret
            boundary="viewport"
          >
            <template #button-content class="text-left">
              <span>
                <div id="dropdown-label" class="dropdown-text blind-text">
                  {{ selectedTeam }}
                  <b-icon
                    class="align-right"
                    icon="caret-down-fill"
                    scale="0.8"
                  ></b-icon>
                </div>
              </span>
            </template>
            <b-dropdown-item-button
              v-for="(team, id) in allTeams"
              :key="id"
              :id="id"
              @click="SelectTeam(id)"
              >{{ team }}</b-dropdown-item-button
            >
          </b-dropdown>
        </div>
        <br />
        <h2>Teammitglieder:</h2>
        <textarea
          class="text-input"
          v-model="teammembers"
          placeholder="Name 1, Name 2, usw..."
        ></textarea>
        <p>
          Trage hier die Namen / Aliase deiner Mitspieler durch <b>Komma getrennt</b>
          ein.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creation_1_Team",
  data() {
    return {};
  },
  computed: {
    teammembers: {
      get() {
        // console.log ("get",this.$store.state.currentAvatarData.teammembers)
        return this.$store.state.currentAvatarData.teammembers;
      },
      set(members) {
        // console.log ("set",this.$store.state.currentAvatarData.teammembers)
        this.$store.dispatch("setTeamMembers", members);
      },
    },
    allTeams() {
      return this.$store.state.allTeams;
    },
    selectedTeam() {
      let label =
        this.$store.state.currentTeam === ""
          ? "Team"
          : this.$store.state.currentTeam;

      let dropdownText = document.getElementById("dropdown-label");
      if (dropdownText != null) {
        if (label === "Team") {
          dropdownText.classList.add("blind-text");
          dropdownText.classList.remove("selected-text");
        } else {
          dropdownText.classList.add("selected-text");
          dropdownText.classList.remove("blind-text");
        }
      }
      return label;
    },
  },
  components: {},
  mounted() {},
  methods: {
    SelectTeam(id) {
      this.$store.dispatch("setCurrentTeam", this.allTeams[id]);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/app.scss";

// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

#view {
  height: calc(100vh - 10vh - 50px - 40px - 16px);
  overflow: auto;
  pointer-events: auto;
}
#content {
  width: 100%;
}
#dropdown-container {
  margin-left: 30px;
  margin-right: 0;
  border: $border-width solid $black;
  border-radius: $border-radius;
}
#dropdown {
  pointer-events: all;
  margin: 0 !important;
}
.text-input {
  padding: 10px;
  pointer-events: all;
  margin-left: 30px;
  margin-right: 0;
  border: $border-width solid $black;
  border-radius: $border-radius;
  width: calc(100% - 30px);
  height: 20vh;
  resize: none;
  background-color: rgba(255, 255, 255, 0.7);
}
.dropdown-text {
  width: 100%;
  text-align: left !important;
}
.blind-text {
  color: gray;
  font-weight: normal;
}
.selected-text {
  color: $black;
  font-weight: bold;
}
.align-right {
  position: absolute;
  right: 10px;
  color: $black;
}
h2 {
  margin-left: 30px;
  font-weight: bold;
  font-size: 1.4em;
}
h3 {
  margin-left: 30px;
  font-size: 1.4em;
}
p {
  margin-left: 30px;
}
@media (min-device-width: 1500px) {
  h2 {
    font-size: 1.6em;
  }
  h3 {
    font-size: 1.6em;
  }
  p {
    font-size: 1.1em;
  }
}
@media (min-device-width: 2000px) {
  #view {
    height: calc(100vh - 10vh - 50px - 40px - 55px - 25px);
  }
}
</style>