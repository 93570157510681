/* jshint esversion: 6 */

import * as THREE from 'three/build/three.module'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'

// import Stats from 'three/examples/jsm/libs/stats.module'

import {
  RoughnessMipmapper
} from 'three/examples/jsm/utils/RoughnessMipmapper'

import {
  RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader'

/// /////////////////////////
//
// keep your hands off everything above
//
// Setup model below
//
/// /////////////////////////

/* model name & path */
const modelname = '3dmodels/future_web_bake.glb'
// const bgname = '3dmodels/Hohlkehle.glb'

/* light & shadow setup */
const useLights = true
let useShadows = false

/* background setup: color names or hex codes (0xff0000) -> red */
const backgroundColor = '#ff000000'

/* hdr / environment setup */
const environmentName = 'img/hdr/reference1_environment.hdr'
const useEnvironment = true
const showEnvironment = false
const exposure = 5

/* debug helper */
// let logModelChildNames = true;
const useStats = true

/// /////////////////////////
//
// Setup model above
//
// keep your hands off everything that follows below
//
/// /////////////////////////
const clock = new THREE.Clock();

let scene, camera, renderer, mixer, /* clock, */ pmremGenerator, controls //, stats
let frustumSize, aspect


let canvas, container
let routeActivated = false

let mouseX = 0,
  mouseY = 0;

let uiWidth = 0;

let camOffsetFactor = 0.0001
let camOffsetFactorTarget = 0.0001

const hotspotObjects = []
const hotspotLastStateObjects = []

let currentDay = 0;
let activeStep = 0;

// const allObjects = new THREE.Object3D()

let modelLoaded = false
const menuOffset = 0

// let orbitChanged = true;

function setUpRender() {
  canvas = document.querySelector('#canvas-three-scene')
  container = document.querySelector('#container-three-scene')
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  uiWidth = document.querySelector('#ui-overlay').offsetWidth;
  // console.log("ui width", uiWidth)
  // console.log(canvas.width)

  renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true
  })

  renderer.autoClear = false

  if (useShadows) {
    renderer.shadowMap.enabled = true
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }
  renderer.setSize(canvas.width, canvas.height)

  pmremGenerator = new THREE.PMREMGenerator(renderer)
  pmremGenerator.compileEquirectangularShader()

  // renderer.toneMapping = THREE.ReinhardToneMapping
  // renderer.toneMappingExposure = exposure
  renderer.gammaFactor = 2.2;
  renderer.outputEncoding = THREE.sRGBEncoding

  // if (useStats) {
  //   stats = new Stats()
  //   container.appendChild(stats.dom)
  // }
};

function setUpCamera() {
  const fov = 60
  aspect = canvas.width / canvas.height
  const near = 0.001
  const far = 200

  // camera = new THREE.PerspectiveCamera(fov, aspect, near, far)

  // camera.setFocalLength(40)
  // camera.filmGauge = 36
  // camera.updateProjectionMatrix()

  frustumSize = 1.5;
  camera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 1, 1000);
  camera.position.z = 2
}

function setUpThreeScene() {
  scene = new THREE.Scene()
  // scene.background = new THREE.Color(backgroundColor)
  // const color = 0xff0000;
  // const density = 0.1;
  // scene.fog = new THREE.FogExp2(color, density);
  scene.add(camera)
  // scene.add(textureCamera);
};

function setUpLight() {
  const hlight = new THREE.AmbientLight(0xffffff, 1.5)
  scene.add(hlight)

  // const directionalLight = new THREE.DirectionalLight(0xffffff, 100)
  // directionalLight.position.set(0, 1, 0)
  // directionalLight.castShadow = true
  // scene.add(directionalLight)

  // const light = new THREE.PointLight(0xc4c4c4, 10)
  // light.position.set(0, 300, 500)
  // scene.add(light)

  // const light2 = new THREE.PointLight(0xc4c4c4, 10)
  // light2.position.set(500, 100, 0)
  // scene.add(light2)

  // const light3 = new THREE.PointLight(0xc4c4c4, 10)
  // light3.position.set(0, 100, -500)
  // scene.add(light3)

  // const light4 = new THREE.PointLight(0xc4c4c4, 10)
  // light4.position.set(-500, 300, 500)
  // scene.add(light4)
};

function setUpControls() {
  // const controls = new OrbitControls(camera, renderer.domElement);
  controls = new OrbitControls(camera, canvas)

  controls.enableZoom = false // Zooming
  controls.minDistance = 1
  controls.maxDistance = 2
  controls.enablePan = false

  // controls.target.set(10000000, 100, 10000000);
  // controls.update();

  controls.autoRotate = true // enable rotation
  controls.maxPolarAngle = Math.PI / 2 // Limit angle of visibility
  // controls.addEventListener('change', onOrbitChanged);

  var autorotateTimeout
  // stop autorotate after the first interaction
  // controls.addEventListener('start', function () {
  //   clearTimeout(autorotateTimeout)
  //   controls.autoRotate = false
  // })

  // // restart autorotate after the last interaction & an idle time has passed
  // controls.addEventListener('end', function () {
  //   autorotateTimeout = setTimeout(function () {
  //     controls.autoRotate = true
  //   }, 10000)
  // })

  return controls
}

function setUpEnvironment() {
  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load(environmentName, function (texture) {
      var envMap = pmremGenerator.fromEquirectangular(texture).texture

      if (showEnvironment) {
        scene.background = envMap
      }
      if (useEnvironment) {
        scene.environment = envMap
      }

      texture.dispose()
      pmremGenerator.dispose()
    })
}

let cubePlanesGroup = undefined

function addCubeToScene() {
  if (cubePlanesGroup === undefined) {
    cubePlanesGroup = new THREE.Object3D()
    // create a geometry
    const geometry = new THREE.PlaneGeometry(1, 1);

    function makeInstance(geometry, colorMapUrl, alphaMapUrl, translation, rotation) {
      const material = new THREE.MeshBasicMaterial();
      const textureLoader = new THREE.TextureLoader();

      material.transparent = true
      material.opacity = 1

      //color.convertSRGBToLinear()

      material.map = textureLoader.load(colorMapUrl)
      material.alphaMap = textureLoader.load(alphaMapUrl)
      // material.side = THREE.DoubleSide
      const hotspotSphere = new THREE.Mesh(geometry, material);

      hotspotSphere.position.x = translation.x;
      hotspotSphere.position.y = translation.y;
      hotspotSphere.position.z = translation.z;
      hotspotSphere.rotateX(rotation.x);
      hotspotSphere.rotateY(rotation.y);
      hotspotSphere.rotateZ(rotation.z);

      scene.add(hotspotSphere);

      cubePlanesGroup.add(hotspotSphere)
    }
    const planes = [
      //makeInstance(geometry, 0xff0000,  0),
      makeInstance(geometry, 'img/textures/color-left.png', 'img/textures/alpha-left.png', new THREE.Vector3(-0.01, 0, 0.5), new THREE.Vector3(0, 0, 0)),
      makeInstance(geometry, 'img/textures/color-right.png', 'img/textures/alpha-right.png',new THREE.Vector3(0.49, 0, 0), new THREE.Vector3(0, Math.PI / 2, 0)),
      makeInstance(geometry, 'img/textures/color-top.png', 'img/textures/alpha-top.png',new THREE.Vector3(-0.01, 0.5, 0), new THREE.Vector3(-Math.PI / 2, 0, 0)),
      // makeInstance(geometry, new THREE.Color(0xffff00), new THREE.Vector3(0, -0.5, 0), new THREE.Vector3(Math.PI / 2, 0, 0)),
      // makeInstance(geometry, new THREE.Color(0xff00ff), new THREE.Vector3(-0.5, 0, 0), new THREE.Vector3(0, -Math.PI / 2, 0)),
      // makeInstance(geometry, new THREE.Color(0x00ffff), new THREE.Vector3(0, 0, -0.5), new THREE.Vector3(0, Math.PI, 0)),
    ];

    // console.log(cubePlanesGroup)
    cubePlanesGroup.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 4)
    cubePlanesGroup.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), Math.PI / 5)
    cubePlanesGroup.scale.x = 0.48
    cubePlanesGroup.scale.y = 0.48
    cubePlanesGroup.scale.z = 0.48
    // cubePlanesGroup.matrixWorldNeedsUpdate = true
    // cubePlanesGroup.updateMatrix()

    scene.add(cubePlanesGroup)

    // allObjects.add(cubePlanesGroup)
  }
}


function removeCubeFromScene() {
  if (cubePlanesGroup != undefined) {
    // cubePlanesGroup.forEach(plane => {
    //   scene.remove(plane)
    //   plane.dispose()
    // });
    scene.remove(cubePlanesGroup)
    cubePlanesGroup = undefined;
  }
}

function addManualHotspotsToScene() {
  // create a geometry
  const geometry = new THREE.BoxGeometry(0.01, 0.01, 0.01);


  function makeInstance(geometry, translation, id, color) {
    const material = new THREE.MeshBasicMaterial({
      color: color ? color : 0xff0000
    });
    const hotspotSphere = new THREE.Mesh(geometry, material);

    hotspotSphere.visible = false
    hotspotSphere.position.x = translation.x;
    hotspotSphere.position.y = translation.y;
    hotspotSphere.position.z = translation.z;

    scene.add(hotspotSphere);

    const hotspot = {}
    const lastStateHotspot = {}

    hotspot.id = id
    lastStateHotspot.id = hotspot.id

    hotspot.object = hotspotSphere
    lastStateHotspot.x = hotspot.object.position.x
    lastStateHotspot.y = hotspot.object.position.y
    lastStateHotspot.z = hotspot.object.position.z

    hotspotObjects.push(hotspot)
    hotspotLastStateObjects.push(lastStateHotspot)
    // console.log(hotspot.object.position);

  }
  const planes = [
    //makeInstance(geometry, 0xff0000,  0),
    makeInstance(geometry, new THREE.Vector3(0, 0, -2.5), '100'), // Aufheller Hintergrund
    makeInstance(geometry, new THREE.Vector3(0, 0, 0), '101'), // Hexa und Grid Hintergrund
    makeInstance(geometry, new THREE.Vector3(0, 0, 0), '102'), // Boden und Hexa-Anschnitt Hintergrund
    makeInstance(geometry, new THREE.Vector3(0.55, 0, 0), '103'), // Team members
    // Charaktermerkmale
    makeInstance(geometry, new THREE.Vector3(0.42, 0.35, 0), '01'), // introvertiert / extravertiert
    makeInstance(geometry, new THREE.Vector3(0.1, -0.04, 0.5), '02'), // offen / beständig
    makeInstance(geometry, new THREE.Vector3(0.21, 0.28, 0), '03'), // gewissenhaft / flexibel
    makeInstance(geometry, new THREE.Vector3(0.33, -0.25, 0), '04'), // kooperativ / kompetitev
    makeInstance(geometry, new THREE.Vector3(0, -0.38, 0), '05'), // sensibel / stabil

    // Motive
    /// Beziehungen
    makeInstance(geometry, new THREE.Vector3(0.23, 0.35, 0), '06'), // Zugehörigkeitsgefühl
    makeInstance(geometry, new THREE.Vector3(-0.5, 0, 0), '07'), // Loyalität zum Team
    makeInstance(geometry, new THREE.Vector3(-0.1, 0.05, 0.3), '08'), // gemeinsame Aufgaben bearbeiten und Ziele erreichen
    /// Werte und Sinn 
    makeInstance(geometry, new THREE.Vector3(-0.1, 0.25, 0.25), '09'), // Übereinstimmung mit eigenen Werten
    makeInstance(geometry, new THREE.Vector3(-0.1, -0.14, 0.25), '10'), // Idealismus
    makeInstance(geometry, new THREE.Vector3(-0.35, -0.1, 0.1), '11'), // Wunsch nach Resonanz
    /// Leistung 
    makeInstance(geometry, new THREE.Vector3(0.22, -0.13, 0.1), '12'), // Streben nach Expertise und Fachkompetenz
    makeInstance(geometry, new THREE.Vector3(-0.37, 0.1, -0.1), '13'), // Aufgaben zur vollsten Zufriedenheit ausführen
    makeInstance(geometry, new THREE.Vector3(0.3, -0.35, 0), '14'), // messbare Erfolge
    /// Wachstum 
    makeInstance(geometry, new THREE.Vector3(-0.1, 0.42, 0.25), '15'), // Freude an großen Herausforderungen und fast unerreichbaren Zielen
    makeInstance(geometry, new THREE.Vector3(0.065, 0.27, 0.35), '16'), // Grenzen im Wertbewerb mit anderen verschieben
    makeInstance(geometry, new THREE.Vector3(-0.3, -0.42, 0.25), '17'), // …Besonderes erreichen

    /// Kreativität 
    makeInstance(geometry, new THREE.Vector3(0.1, 0.15, 0.1), '18'), // neues Wissen aneignen
    makeInstance(geometry, new THREE.Vector3(-0.45, -0.25, 0.2), '19'), // an Lösungen für komplexe Herausforderungen tüfteln
    makeInstance(geometry, new THREE.Vector3(-0.1, -0.25, 0.25), '20'), // frei und unkonventionell denken
    /// Einfluss 
    makeInstance(geometry, new THREE.Vector3(-0.27, 0.45, -0.25), '21'), // Anerkennung und Prestige
    makeInstance(geometry, new THREE.Vector3(0.25, 0.13, 0.25), '22'), // Macht haben
    makeInstance(geometry, new THREE.Vector3(0.5, 0.12, 0), '23'), // Verantwortung übernehmen
    /// Unabhängigkeit 
    makeInstance(geometry, new THREE.Vector3(0.05, 0.38, -0.41), '24'), // Eigeninitiative und Autonomie
    makeInstance(geometry, new THREE.Vector3(-0.25, 0.33, -0.41), '25'), // eigenbestimmter Entscheidungsspielraum
    makeInstance(geometry, new THREE.Vector3(-0.436, -0.34, 0.15), '26', new THREE.Color(0x0000ff)), // Selbstständigkeit
    /// Sicherheit 
    makeInstance(geometry, new THREE.Vector3(0.31, 0.03, 0), '27'), // stabile Beziehungen
    makeInstance(geometry, new THREE.Vector3(-0.5, 0.25, 0), '28'), // gesicherte Arbeitsplätze
    makeInstance(geometry, new THREE.Vector3(0, -0.55, 0.25), '29'), // ausreichend Orientierung und Kontrolle

    /// Fähigkieten 
    makeInstance(geometry, new THREE.Vector3(-0.2, -0.05, -0.25), '30'), // Netzwerken
    makeInstance(geometry, new THREE.Vector3(0.1, -0.18, 0.25), '31'), // Entscheiden
    makeInstance(geometry, new THREE.Vector3(0.35, 0.21, 0.35), '32'), // Empathie
    makeInstance(geometry, new THREE.Vector3(0, 0.54, -0.5), '33'), // Kreativität
    makeInstance(geometry, new THREE.Vector3(-0.23, 0.20, 0.25), '34'), // Überzeugungsvermögen
    makeInstance(geometry, new THREE.Vector3(-0.18, -0.35, 0.25), '35'), // Konfliktkompetenz
    makeInstance(geometry, new THREE.Vector3(0.33, -0.13, 0), '36'), // Zukunftswahrnehmung
  ];

  //console.log(hotspotObjects)

  modelLoaded = true

  hotspotObjects.forEach((hotspot) => {
    HotspotPositionChanged(hotspot)
    // allObjects.add(hotspot.object)
  })

  //allObjects.position.x = 0.1
}

let model = undefined

function addModelToScene() {
  if (model === undefined) {
    modelLoaded = false

    const loader = new GLTFLoader()

    // A reusable function to set up the models. We're passing in a position parameter
    // so that they can be individually placed around the scene
    const onLoad = (gltf, position, _color) => {
      // console.log('started model loading');

      // use of RoughnessMipmapper is optional
      var roughnessMipmapper = new RoughnessMipmapper(renderer)

      model = gltf.scene // .children[0];

      model.scale.set(0.16, 0.16, 0.16)
      model.position.copy(position)

      model.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), -Math.PI / 4)
      model.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), Math.PI / 5)

      // console.log(model.material)

      // model.material = new THREE.MeshBasicMaterial();
      // model.material.alphaMap = 'img/textures/Ramp2.png'
      // model.material.map = 'img/textures/cube_colors.jpg'
      // model.material.transparent = true

      // model.material.needsUpdate = true

      model.traverse(function (child) {
        // shadows
        // if (child.isMesh) {
        //   roughnessMipmapper.generateMipmaps(child.material)

        //   if (useShadows) {
        //     if (child.name.includes('##receive')) {
        //       child.receiveShadow = true
        //       // console.log("RECEIVE - " + child.name);
        //     }
        //     if (child.name.includes('##cast')) {
        //       child.castShadow = true
        //       // console.log("CAST - " + child.name);
        //     }
        //   }
        // } else if (child.isLight) {
        //   if (useShadows) {
        //     child.castShadow = true
        //   }
        // }
        // hotspots
        // if (child.name.includes('##hotspot-')) {
        //   const splits = child.name.split('##')
        //   const hotspot = {}
        //   const lastStateHotspot = {}

        //   splits.forEach(split => {
        //     // console.log("SPLIT - " + split);
        //     if (split.includes('hotspot-')) {
        //       const secSplits = split.split('-')
        //       if (secSplits.length > 1) {
        //         hotspot.id = secSplits[1]
        //         lastStateHotspot.id = hotspot.id
        //       }
        //     }
        //   })

        //   hotspot.name = splits[0]
        //   hotspot.object = child
        //   lastStateHotspot.x = hotspot.object.position.x
        //   lastStateHotspot.y = hotspot.object.position.y
        //   lastStateHotspot.z = hotspot.object.position.z

        //   hotspotObjects.push(hotspot)
        //   hotspotLastStateObjects.push(lastStateHotspot)
        //   // console.log(hotspot.object.position);
        // }
        // if ( /*_color && */ child.material) {
        //   // console.log(child)
        //   console.log(child.name);
        //   console.log("original", child.material)

        //   const textureLoader = new THREE.TextureLoader();

        //   child.material = new THREE.MeshStandardMaterial();
        //   child.material.transparent = true
        //   child.material.opacity = 1
        //   //child.material.color = new THREE.Color(10, 50, 255)
        //   //child.material.alphaMap = textureLoader.load('img/textures/Ramp2.png')
        //   child.material.map = textureLoader.load('3dmodels/Test_Cube_Web01_img0.png')

        //   ///console.log(tex)

        //   console.log("new", child.material)
        //   child.material.needsUpdate = true

        //   // child.material.color.set(_color)
        // }
        // if (logModelChildNames) {

        // console.log(child.name);
        // }
      })


      scene.add(model)
      roughnessMipmapper.dispose()

      mixer = new THREE.AnimationMixer(model)
      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play()
      })

      modelLoaded = true
      // console.log('finished model loading');

      window.addEventListener(
        'SliderValueChanged',
        (e) => {
          if (modelLoaded) {
            // console.log(e);

            // gltf.animations.forEach((clip) => {
            //   var action = mixer.clipAction(clip)
            //   action.setLoop(THREE.LoopOnce)

            //           if (e.detail) {
            //             action.startAt(clip.duration* e.detail.value)
            // console.log('inner ' + e.detail.value)
            // action.reset()
            // action.timeScale = 1
            // action.clampWhenFinished = true
            // action.play()
            // }
            // else {
            //   action.paused = false
            //   action.timeScale = -1
            //   action.play()
            // }

            mixer.setTime(e.detail.value)
            // })
          }
        },
        false
      )

      const event = new Event('three-scene_modelLoaded')

      window.dispatchEvent(event)

      hotspotObjects.forEach((hotspot) => {
        HotspotPositionChanged(hotspot)
      })

    }

    // the loader will report the loading progress to this function
    const onProgress = (progress) => {
      // console.log(progress.loaded / progress.total)

      const event = new CustomEvent('three-scene_loadingprogress', {
        detail: progress.loaded / progress.total
      })

      window.dispatchEvent(event)
    }

    // the loader will send any error messages to this function, and we'll log
    // them to to console
    const onError = (errorMessage) => {
      console.log(errorMessage)
    }

    // load the first model. Each model is loaded asynchronously,
    // so don't make any assumption about which one will finish loading first
    const modelPosition = new THREE.Vector3(0, 0.1, 0)

    loader.load(modelname, gltf => onLoad(gltf, modelPosition), onProgress, onError)
    // loader.load(bgname, gltf => onLoad(gltf, modelPosition, '#004a4f'), onProgress, onError)
    // if (hotspotObjects.length > 0) {
    // hotspotObjects.forEach((hotspot) => {
    //     HotspotPositionChanged(hotspot);

    // })
    // }

    return model
  }
};

function removeModellFromScene() {
  if (model != undefined) {
    scene.remove(model)
    model.dispose();
    model = undefined;
  }
}

function onWindowResize() {
  canvas.width = container.offsetWidth
  canvas.height = container.offsetHeight - menuOffset

  aspect = canvas.width / canvas.height

  //camera.aspect = aspect

  camera.left = frustumSize * aspect / -2;
  camera.right = frustumSize * aspect / 2;

  camera.updateProjectionMatrix()

  renderer.setSize(canvas.width, canvas.height)

  uiWidth = document.querySelector('#ui-overlay').offsetWidth;
  // console.log("ui width", uiWidth)
}

function HotspotPositionChanged(hotspot) {
  // calc screenspace position
  const screenPosition = toScreenPosition(hotspot.object, camera)

  const event = new CustomEvent('hotspotPositionChanged', {
    detail: {
      id: hotspot.id,
      x: screenPosition.x,
      y: screenPosition.y + menuOffset / 2
    }
  })

  window.dispatchEvent(event)
}

function toScreenPosition(obj, camera) {
  var vector = new THREE.Vector3()

  var widthHalf = 0.5 * canvas.width
  var heightHalf = 0.5 * canvas.height

  obj.updateMatrixWorld()
  camera.updateProjectionMatrix()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = (vector.x * widthHalf) + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y
  }
};

function render(time) {
  time *= 0.001 // convert time to seconds
  // console.log(time);
  var delta = clock.getDelta()

  if (mixer) mixer.update(delta)

  // if (useStats) {
  //   stats.update()
  // }
  if (modelLoaded) {
    hotspotObjects.forEach((hotspot) => {
      hotspotLastStateObjects.forEach((lastStateHotspot) => {
        if (hotspot.id === lastStateHotspot.id) {
          HotspotPositionChanged(hotspot)

          lastStateHotspot.x = hotspot.object.position.x
          lastStateHotspot.y = hotspot.object.position.y
          lastStateHotspot.z = hotspot.object.position.z
        }
      })
    })
  }

  // orbitChanged = false;
  //controls.update()
  if (mouseX >= uiWidth - canvas.width / 2 || currentDay === 4 || activeStep === 6) {

    let xPos = (mouseX - camera.position.x) * 0.0012 > 1.1 ? 1.1 :
      (mouseX - camera.position.x) * 0.0012 < -1.1 ? -1.1 :
      (mouseX - camera.position.x) * 0.0012
    camera.position.x = -xPos;

    let yPos = (-mouseY - camera.position.y) * 0.0012 > 0.17 ? 0.17 :
      (-mouseY - camera.position.y) * 0.0012 < -0.17 ? -0.17 :
      (-mouseY - camera.position.y) * 0.0012

    camera.position.y = -yPos
    // console.log(camera.position.y)
  } else {
    if (camera.position.x <= -(camOffsetFactor * canvas.width)) {
      camera.position.x = camera.position.x >= -(camOffsetFactor * canvas.width) - 0.01 ?
        -(camOffsetFactor * canvas.width) : camera.position.x + 0.01
    } else if (camera.position.x >= -(camOffsetFactor * canvas.width)) {
      camera.position.x = camera.position.x <= -(camOffsetFactor * canvas.width) + 0.01 ?
        -(camOffsetFactor * canvas.width) : camera.position.x - 0.01
    }

    if (camera.position.y <= 0) {
      camera.position.y = camera.position.y >= -0.01 ?
        0 : camera.position.y + 0.01
    } else if (camera.position.y >= 0) {
      camera.position.y = camera.position.y <= +0.01 ?
        0 : camera.position.y - 0.01
    }
  }

  // console.log(camera.position.x)
  camera.lookAt(new THREE.Vector3(scene.position.x - (camOffsetFactor * canvas.width), scene.position.y, scene.position.z));

  // cam offset if menu is opended / closed // on 4th day
  if (Math.abs(camOffsetFactor - camOffsetFactorTarget) <= 0.000001) {
    camOffsetFactor = camOffsetFactorTarget
  }
  if (camOffsetFactor != camOffsetFactorTarget) {
    if (camOffsetFactor < camOffsetFactorTarget) {
      camOffsetFactor += 0.000001
    } else if (camOffsetFactor > camOffsetFactorTarget) {
      camOffsetFactor -= 0.000001
    }
  }

  renderer.render(scene, camera)
  // if (routeActivated) {
  requestAnimationFrame(render)
  // }
};

function setCamOffsetFactorTarget() {
  if (currentDay === 4 || activeStep === 6) {
    camOffsetFactorTarget = 0
  } else {
    camOffsetFactorTarget = 0.00015
  }
}

function onPointerMove(event) {

  if (event.isPrimary === false) return;

  mouseX = event.clientX - canvas.width / 2;
  mouseY = event.clientY - canvas.height / 2;
}

function onChangeCurrentDay(event) {
  currentDay = event.detail.currentDay
  setCamOffsetFactorTarget()

  if (currentDay === 4) {
    removeCubeFromScene();
    addModelToScene();
  }
  else{
    removeModellFromScene();
    addCubeToScene();
  }
  // console.log("curr day")
}

function onChangeActiveStep(event) {
  activeStep = event.detail.activeStep
  setCamOffsetFactorTarget()
  // console.log("act step")
}

export async function main(_isMobile, _activeStep, _currentDay) {
  // clock = new THREE.Clock()
  activeStep = _activeStep
  currentDay = _currentDay
  // console.log("in scene ", _isMobile)
  useShadows = !_isMobile
  setUpRender()

  setUpCamera()

  setUpThreeScene()

  if (useLights) {
    setUpLight()
  }
  //setUpControls(camera, renderer)

  if (useEnvironment || showEnvironment) {
    setUpEnvironment()
  }

  window.addEventListener('resize', onWindowResize, false)
  window.addEventListener('changeCurrentDay', onChangeCurrentDay, false)
  window.addEventListener('changeActiveStep', onChangeActiveStep, false)
  document.body.addEventListener('pointermove', onPointerMove, false);
  // window.addEventListener('SliderValueChanged', onSliderValueChanged, false)
  // await addModelToScene()
  addCubeToScene();
  addManualHotspotsToScene();

  requestAnimationFrame(render)
}
