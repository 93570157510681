<template>
  <div id="app">
    <ThreeScene />
  </div>
</template>

<script>
import ThreeScene from "./components/ThreeScene.vue";

export default {
  name: "App",
  components: {
    ThreeScene,
  },
};
</script>

<style lang="scss">
@import "@/scss/app.scss";

// Import Bootstrap and BootstrapVue source SCSS files
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

@font-face {
  font-family: PTSans;
  src: url("/fonts/PTSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: PTSans;
  src: url("/fonts/PTSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: PTSans;
  src: url("/fonts/PTSans-italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: PTSans;
  src: url("/fonts/PTSans-Regular.ttf");
}
#app {
  background-image: url("./assets/220124_Avatar_BG_v2_4k.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0); /* Tint color */
  background-blend-mode: multiply;
  // background-color: rgb(78, 78, 78);
  font-family: PTSans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $black;
  margin-top: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
body {
  overflow: hidden;
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
#sliders .progress {
  border-radius: 1rem;
}
#slider-container {
  .bg-dark {
    background-color: #343434 !important;
  }
}
#button-container {
  .text-dark {
    color: #343434 !important;
  }
}

#modal-1 .modal-footer,
#modal-2 .modal-footer {
  justify-content: center !important;
}
#checkboxes .custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $black;
  background-color: $black;
}

#radioContainer .custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $black;
  background-color: $white;
}
#radioContainer .custom-control-label::before,
#checkboxes .custom-control-label::before {
  background-color: $white;
  border: $black solid 2px;
}
#checkboxes .custom-checkbox .custom-control-label::before {
  border-radius: 0.1rem;
}
#radioContainer .custom-control-input:focus ~ .custom-control-label::before,
#checkboxes .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.1rem $dark_gray;
  -webkit-box-shadow: inset 0 0 0.1rem $dark_gray;
}
#checkboxes .custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  border: #838b92 solid 2px;
}
#dropdown-container .btn-secondary {
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

#dropdown-container .dropdown-menu {
  overflow: auto;
  max-height: 100vh;
}

#spoiler .btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 26, 59, 0);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #999999 #dbdbdb;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #dbdbdb;
}

*::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 3px solid #999999;
}
</style>
