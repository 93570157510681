import Vue from 'vue'
import App from './App.vue'
import store from './store'
// import router from './router'

import { BootstrapVue, IconsPlugin  } from 'bootstrap-vue'

import './scss/app.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  store,
  // router,
  render: h => h(App)
}).$mount('#app')
