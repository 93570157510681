<template>
  <div>
    <div id="view">
      <h3>
        Auf Basis eurer Simulationserfahrungen: <br> Wenn ihr nur auf
        <b>1 Fähigkeit</b>
        setzen könntet, die im metaZukunftsraum "interLab" besonders wichtig
        ist – welche wäre das?
      </h3>
      <div id="radioContainer">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group"
            v-model="selectedRadioButton"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
            stacked
          >
            <b-form-radio
              v-for="(radioLabel, id) in radioButtonLabels"
              :key="id"
              :aria-describedby="ariaDescribedby"
              name="some-radios"
              class="allow-pointer"
              :value="id"
              >{{ radioButtonLabels[id] }}</b-form-radio
            >
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Creation_4_Skills",
  data() {
    return {
      selected: 0,
    };
  },
  computed: {
    radioButtonLabels() {
      return this.$store.state.content.skills.radioButtonLabels;
    },
    selectedRadioButton: {
      get() {
        // console.log("get",this.$store.state.content.skills.selectedRadioButton);
        return this.$store.state.content.skills.selectedRadioButton;
      },
      set(id) {
        this.$store.dispatch("setSelectedRadioButton", id);

        for (
          let i = 0;
          i < this.$store.state.content.skills.radioButtonLabels.length;
          i++
        ) {
          let param = {
            id:
              i +
              this.$store.state.content.characteristics.sliders.length +
              this.$store.state.content.motives.checkboxesStates.length,
            value: id === i
              ? 1
              : 0,
          };
          this.$store.dispatch("setAvatarParameter", param);
        }
      },
    },
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#view {
  height: calc(100vh - 10vh - 50px - 40px - 16px);
  overflow: auto;
  pointer-events: auto;
}
#radioContainer{
  margin: 30px;
}
.allow-pointer {
  pointer-events: auto;
  width: fit-content;
  margin-bottom: 5px;
}
h3 {
  margin-left: 30px;
  font-size: 1.4em;
}
@media (min-device-width: 1500px) {
  h3 {
    font-size: 1.6em;
  }
  p {
    font-size: 1.1em;
  }
  #radioContainer{
    transform: scale(1.2);
    transform-origin: left top;
    width: 70%;
    overflow-x: hidden;
  }
}

@media (min-device-width: 2000px) {
  #view {
    height: calc(100vh - 10vh - 50px - 40px - 55px - 25px);
  }
}
</style>